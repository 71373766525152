import {
  Engagement,
  IdentityTokenProfile,
  EntityEngagement,
  K1Header,
  EngagementTemplate,
} from '../clientModels'
import { PhaseCode, UserPermissions } from '../enums'
import { reviewIsComplete, userIsReviewer } from './questionReviewUtilities'
import { hasPermission } from './permissions'
export interface ButtonConfiguration {
  actionButtonText?: string
  navButtonText?: string
  phaseButtonText?: string
  statusText?: string
}

export function clientUserButtonConfig(
  engagement: Engagement
): ButtonConfiguration {
  const config: ButtonConfiguration = {
    navButtonText: 'View',
  }
  const phaseStarted = engagement.successfulQuestions > 0
  const phaseComplete =
    engagement.successfulQuestions >= engagement.totalQuestions

  switch (engagement.phase) {
    case PhaseCode.PBC:
      config.navButtonText = 'Continue Engagement'
      config.phaseButtonText = phaseComplete ? 'SUBMIT' : ''
      config.statusText = phaseComplete ? 'Ready to Submit' : 'In Progress'
      break

    case PhaseCode.ClientValidation:
      config.navButtonText = phaseStarted ? 'Continue Engagement' : 'Begin'
      config.statusText = phaseStarted ? 'In Progress' : 'Not Started'
      break

    case PhaseCode.Review:
      config.statusText = 'Review in Progress'
      break

    default:
      break
  }

  return config
}

export function rsmUserButtonConfig(
  engagement: Engagement,
  user: IdentityTokenProfile,
  engagementTemplate: EngagementTemplate
): ButtonConfiguration {
  const config: ButtonConfiguration = {
    navButtonText: 'View',
  }

  const latestExportTaxIntegrationStatus =
    engagement.taxIntegrationStatuses &&
    engagement.taxIntegrationStatuses
      .filter(x => x.type === 'CCHExport')
      .sort((x, y) => y.id - x.id)[0]
  const phaseComplete =
    engagement.successfulQuestions >= engagement.totalQuestions

  switch (engagement.phase) {
    case PhaseCode.Setup:
      config.navButtonText = 'Continue Setup'
      config.phaseButtonText = 'Send Engagement Invite'
      break

    case PhaseCode.ClientValidation:
    case PhaseCode.PBC:
      config.statusText =
        engagement.successfulQuestions > 0 ? 'In Progress' : 'Not Started'
      break

    case PhaseCode.Review:
      if (!userIsReviewer(engagement, user)) {
        break
      }

      if (!reviewIsComplete(engagement, user)) {
        // Review is not complete for the current user
        config.navButtonText = 'Continue Review'
        config.actionButtonText = 'Done with Review'
      }

      if (reviewIsComplete(engagement)) {
        // Review is complete for all users
        if (engagementTemplate.hasCchPhase) {
          config.phaseButtonText = 'Submit to CCH'
        } else {
          config.phaseButtonText = 'Mark as Filed'
        }
      }

      break

    case PhaseCode.CCH:
      if (!phaseComplete) {
        if (
          latestExportTaxIntegrationStatus &&
          (latestExportTaxIntegrationStatus.status === 'ERROR' ||
            latestExportTaxIntegrationStatus.status === 'SUCCESS' ||
            latestExportTaxIntegrationStatus.status === 'PARTIAL SUCCESS')
        ) {
          // Submit to CCH failed. Allow current user to resubmit
          config.navButtonText = 'View/Edit'
          config.phaseButtonText = 'Submit to CCH'
        }
      } else {
        config.phaseButtonText = 'Mark As Filed'
      }

      break

    case PhaseCode.IRS:
      config.statusText = 'Filed with IRS'
      break
    case PhaseCode.Closed:
      if (engagement.rollForwardDate) {
        config.statusText = 'Rolled Forward'
      } else {
        if (
          engagement.nextYearTemplateAvailable &&
          engagement.engagementTaxForm &&
          ['990', '990-EZ', '990-PF'].indexOf(engagement.engagementTaxForm) !== -1
        ) {
          config.actionButtonText = 'Roll Forward'
        }
      }
      break

    default:
      break
  }

  return config
}

const questionDisabledInternalPhases = [
  PhaseCode.CCH,
  PhaseCode.IRS,
  PhaseCode.Closed,
  undefined,
]

export function engagementLockedPhase(
  engagement: Engagement | EntityEngagement
): boolean {
  const disabledByInternalPhase = questionDisabledInternalPhases.includes(
    engagement.phase
  )
  return disabledByInternalPhase
}

export function canUnlockEngagment(
  engagement: Engagement,
  user: IdentityTokenProfile
): boolean {
  const canUnlock = hasPermission(UserPermissions.EngagementsCanEdit, user)
  const phase = engagement.phase
  return phase !== 'closed' || canUnlock
}

export function engagementLocked(engagement: Engagement): boolean {
  return engagement
    ? engagement.isEnabled !== undefined
      ? engagement.isEnabled
      : engagementLockedPhase(engagement)
    : true
}

export function k1Locked(k1: K1Header, engagement: Engagement): boolean {
  const isInProgress = (/in progress/gi).test(k1.status)
  return isInProgress || engagementLocked(engagement)
}
